<template>
  <div>
    <v-text-field v-model="name" label="Name" required outlined></v-text-field>
    <v-text-field v-model="code" label="Code" required outlined></v-text-field>
    <v-select
      v-model="selectedTests"
      :items="tests"
      :menu-props="{ maxHeight: '400' }"
      :rules="testRules"
      item-value="_id"
      item-text="name"
      label="Testler"
      multiple
      hint="Sınav içinde olacak testler"
      outlined
      persistent-hint
    ></v-select>
    <v-btn
      block
      elevation="3"
      :disabled="!valid"
      color="primary"
      large
      @click="create"
    >
      CREATE
    </v-btn>
  </div>
</template>
<script>
import axios from '@axios';

export default {
  name: 'FirmCreate',

  components: {},
  data: () => ({
    name: '',
    code: '',
    tests: [],
    selectedTests: [],
    testRules: [(v) => v.length !== 0 || 'En az bir test seçilmeli']
  }),
  computed: {
    valid() {
      return this.name.length && this.code.length;
    }
  },
  mounted() {
    axios
      .get('/list-test')
      .then((response) => {
        if (response?.data?.result) {
          this.tests = response.data.result;
        }
      })
      .catch(() => {
        this.$toast.error('Error while getting test list');
      });
  },

  methods: {
    create() {
      axios
        .post('/create-firm', {
          name: this.name,
          code: this.code,
          selectedtests: this.selectedTests
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success('Successfully created firm');
            this.name = '';
            this.code = '';
            this.$emit('firmAdded');
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    }
  }
};
</script>

<style lang="scss"></style>
