<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :loading="items.length === 0"
      item-key="name"
      expanded.sync
      multi-sort
      show-expand
      single-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogEdit" max-width="750px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Firma Düzenleme</span>
              </v-card-title>
              <v-card-text>
                <v-container class="grey lighten-5">
                  <v-col md="12" lg="12" xl="12">
                    <v-card class="pa-1" outlined shaped>
                      <v-card-title>Firma Bilgileri</v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-model="editedItem.name"
                          label="Firma İsmi"
                          :rules="editedItem.nameRules"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.code"
                          label="Firma Kodu"
                          outlined
                        ></v-text-field>
                        <v-select
                          v-model="editedItem.selectedTests"
                          :items="tests"
                          :menu-props="{ maxHeight: '400' }"
                          item-value="_id"
                          item-text="name"
                          label="Testler"
                          multiple
                          hint="Sınav içinde olacak testler"
                          outlined
                          persistent-hint
                        ></v-select>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDialogEdit">
                  İptal Et
                </v-btn>
                <v-btn color="green darken-1" text @click="saveDialogEdit">
                  Kaydet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Silmek İstediğinizden Emin Misiniz ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDelete"
                  >İptal Et</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Evet</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.isActive"
          small
          class="mr-2"
          @click="passivateItem(item)"
        >
          mdi-check-bold
        </v-icon>
        <v-icon v-else small class="mr-2" @click="activateItem(item)">
          mdi-close-thick
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="p-0" :colspan="headers.length">
          <v-data-table
            style="border-radius: 0"
            :headers="userHeaders"
            :items="item.users"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogUserEdit" max-width="750px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Kullanıcı Düzenleme</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container class="grey lighten-5">
                        <v-col md="12" lg="12" xl="12">
                          <v-card class="pa-1" outlined shaped>
                            <v-card-title>Kullnıcı Bilgileri</v-card-title>
                            <v-card-text>
                              <v-text-field
                                v-model="editedUserItem.username"
                                label="Kullanıcı Username"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedUserItem.name"
                                label="Kullanıcı İsmi"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedUserItem.lastname"
                                label="Kullanıcı Soyadı"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedUserItem.password"
                                label="Kullanıcı Şifresi"
                                outlined
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="closeDialogUserEdit"
                      >
                        İptal Et
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="saveDialogUserEdit"
                      >
                        Kaydet
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItemUser(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'FirmList',
  components: {},
  props: {
    update: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data: () => ({
    userHeaders: [
      {
        text: 'Username',
        align: 'start',
        value: 'username'
      },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Surname',
        value: 'lastname'
      },
      {
        text: 'İşlemler',
        value: 'actions',
        width: '100px',
        sortable: false
      }
    ],
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Code',
        value: 'code'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
      {
        text: 'İşlemler',
        value: 'actions',
        width: '100px',
        sortable: false
      }
    ],
    items: [],
    tests: [],
    editedItem: {
      name: '',
      code: '',
      selectedTests: []
    },
    editedUserItem: {
      username: '',
      name: '',
      lastname: '',
      password: ''
    },
    selectedDeleteItemId: '',
    dialogDelete: false,
    dialogEdit: false,
    dialogUserEdit: false
  }),
  computed: {
    valid() {
      return this.name.length && this.code.length;
    }
  },
  watch: {
    update: function () {
      this.list();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeDialogEdit();
    }
  },
  mounted() {
    this.list();
    this.listTest();
  },
  methods: {
    list() {
      axios.get('/list-firm').then((response) => {
        const items = response.data.result;
        this.items = items;
      });
    },
    activateItem(item) {
      axios
        .post(`/activate-firm/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Firma Aktif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    passivateItem(item) {
      axios
        .post(`/passivate-firm/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Firma Pasif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedDeleteItemId = item._id;
    },
    editItem(item) {
      const _selectedTests = [];

      for (let index = 0; index < this.tests.length; index++) {
        const element = this.tests[index];

        if (element.firms.indexOf(item._id) >= 0) {
          _selectedTests.push(element._id);
        }
      }

      this.editedItem = {
        _id: item._id,
        name: item.name,
        code: item.code,
        selectedTests: _selectedTests
      };
      this.dialogEdit = true;
    },
    editItemUser(item) {
      this.editedUserItem = {
        _id: item._id,
        name: item.name,
        username: item.username,
        lastname: item.lastname
      };
      this.dialogUserEdit = true;
    },
    deleteItemConfirm() {
      this.closeDelete();
      axios
        .post(`/delete-firm/${this.selectedDeleteItemId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Firma Silindi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialogEdit() {
      this.dialogEdit = false;
    },
    saveDialogEdit() {
      axios
        .post(`/edit-firm/${this.editedItem._id}`, {
          code: this.editedItem.code,
          name: this.editedItem.name,
          selectedtests: this.editedItem.selectedTests
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Firma Güncellendi.');
            this.list();
            this.listTest();
            this.dialogEdit = false;
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDialogUserEdit() {
      this.dialogUserEdit = false;
    },
    saveDialogUserEdit() {
      axios
        .post(`/edit-user/${this.editedUserItem._id}`, this.editedUserItem)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Kullanıcı Güncellendi.');
            this.list();
            this.dialogUserEdit = false;
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    listTest() {
      axios
        .get('/list-test')
        .then((response) => {
          if (response?.data?.result) {
            this.tests = response.data.result;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting test list');
        });
    }
  }
};
</script>

<style lang="scss"></style>
